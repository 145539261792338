
export default {
  name: 'ChatbotTrigger',
  methods: {
    triggerChat(){
        const popoverTriggerEvent = new CustomEvent(
          "keplero-popover-trigger",
          {
            detail: {
              chatbot: "16662b44-a7b3-42d7-92bd-bca632c45b83",
            },
          }
        );
        window.dispatchEvent(popoverTriggerEvent)
      }
  }
}
