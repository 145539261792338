// import { getSalesChannelToken } from '@commercelayer/js-auth'
import { authenticate } from '@commercelayer/js-auth'

import CommerceLayer from '@commercelayer/sdk'
import countries from '~/assets/data/countries_ecommerce'

const organization = 'vitesy'

export const state = () => ({
  accessToken: false,
  orderId: false,
  order: false,
  market: '',
  currency: 'EUR',
  products: [],
  country: false,
  business: false,
  isLoading: true,
  activePage: false,
  customerEmail: null,
  utmParams: {},
  toast: {
    show: false,
    message: ""
  }
})

export const mutations = {
  setActivePage(state, page) {
    state.activePage = page
  },
  setMarket(state, market) {
    state.market = market
    if (process.browser) {
      localStorage.setItem('vitesyMarket', market)
    }
  },
  setCountry(state, country) {
    state.country = country
    if (process.browser) {
      localStorage.setItem('vitesyCountry', JSON.stringify(country))
    }
    // Redirect
    // if (country.code === 'IT') {
    //   this.$router.push(this.switchLocalePath('it'))
    // } else if (country.code === 'DE') {
    //   this.$router.push(this.switchLocalePath('de'))
    // } else {
    //   this.$router.push(this.switchLocalePath('en'))
    // }
  },
  setCurrency(state, currency) {
    state.currency = currency
  },
  setBusiness(state, business) {
    state.business = business
    if (process.browser) {
      localStorage.setItem('vitesyBusiness', business.toString())
    }
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
  },
  setOrder(state, order) {
    state.order = order
    if (process.browser) {
      !order || (order && order.status === 'placed') ? localStorage.removeItem(`vitesyOrderId_${state.market}`) : localStorage.setItem(`vitesyOrderId_${state.market}`, order.id)
    }
  },
  setProducts(state, products) {
    state.products = products
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setCustomerEmail(state, email) {
    state.customerEmail = email;
  },
  setUtmParams(state, params) {
    state.utmParams = params;
  },
  showToast(state, message) {
    state.toast.show = true;
    state.toast.message = message;
  },
  hideToast(state) {
    state.toast.show = false;
    state.toast.message = '';
  }
}

export const actions = {
  // async nuxtServerInit({
  //   dispatch
  // }) {
  //   await dispatch('initCommerceLayer')
  // },
  async setActivePage({
    commit
  }, page) {
    await commit('setActivePage', page)
  },
  async initCommerceLayer({
    commit,
    state
  }) {
    if (state.market) {
      try {

        const auth = await authenticate('client_credentials', {
          clientId: process.env.COMMERCELAYER_CLIENT_ID,
          scope: `market:id:${state.market}`
        })
        // const token = await getSalesChannelToken({
        //   clientId: process.env.COMMERCELAYER_CLIENT_ID,
        //   endpoint: process.env.COMMERCELAYER_ENDPOINT,
        //   scope: `market:id:${state.market}`
        // })
        commit('setAccessToken', auth.accessToken)
      } catch (err) {
        return false
      }
    }
  },
  // Init
  pageUrl({ state }, type) {
    let path = '/'
    if (type === 'privacy') {
      path = '/privacy-policy'
    } else if (type === 'cart') {
      path = '/cart'
    } else if (type === 'terms') {
       path = state.business ? '/terms-and-conditions-business' : '/terms-and-conditions'
      // if (this.$config.intelligenceAppliancesEnabled && state.country.code === 'GB') {
      //   return 'https://www.intelligentappliances.co.uk/terms-and-conditions/'
      // }
    }
    return  `${this.$config.domain}${this.app.localePath(path)}`
  },
  async setMarket({
    commit,
    dispatch,
    state
  }, detect) {
    await commit('setLoading', true)
    let storageMarket = false
    let storageBusiness = false
    if (process.browser) {
      storageMarket = localStorage.getItem(`vitesyMarket`)
      storageBusiness = localStorage.getItem('vitesyBusiness') === 'true'
      await commit('setBusiness', storageBusiness)
    }
    let activeMarket = !detect ? storageMarket || false : false
    if (!activeMarket) {
      if (state.country.code === 'IT') {
        state.business ? activeMarket = process.env.CL_MARKET_IT_B2B_EUR : activeMarket = process.env.CL_MARKET_IT_B2C_EUR
      } else if (state.country.code === 'GB' || state.country.code === 'UK') {
        state.business ? activeMarket = process.env.CL_MARKET_GB_B2B_GBP : activeMarket = process.env.CL_MARKET_GB_B2C_GBP
      } else if (state.country.code === 'DE' || state.country.code === 'AT') {
        state.business ? activeMarket = process.env.CL_MARKET_DA_B2B_EUR : activeMarket = process.env.CL_MARKET_DA_B2C_EUR
      } else if (state.country.code === 'FR') {
        state.business ? activeMarket = process.env.CL_MARKET_FR_B2B_EUR : activeMarket = process.env.CL_MARKET_FR_B2C_EUR
      } else if (['NO', 'CH', 'IS', 'LI', 'AD', 'MC', 'SM'].includes(state.country.code)) {
        state.business ? activeMarket = process.env.CL_MARKET_EXTRA_EU_B2B_EUR : activeMarket = process.env.CL_MARKET_EXTRA_EU_B2C_EUR
      } else if (state.country.is_eu && state.country.code !== 'IT') {
        state.business ? activeMarket = process.env.CL_MARKET_EU_B2B_EUR : activeMarket = process.env.CL_MARKET_EU_B2C_EUR
      } else if (state.country.is_eu || !state.country?.code) {
        state.business ? activeMarket = process.env.CL_MARKET_EU_B2B_EUR : activeMarket = process.env.CL_MARKET_EU_B2C_EUR
      } else {
        state.business ? activeMarket = process.env.CL_MARKET_US_CA_B2B_USD : activeMarket = process.env.CL_MARKET_US_CA_B2C_USD
      }
    }
      // console.log(`ACTIVE MARKET: Country ${state.country.code} + isEU ${state.country.is_eu} + isBusiness ${state.business} = ${activeMarket}`)

      const oldMarket = state.market || false
      await commit('setMarket', activeMarket)
      await dispatch('initCommerceLayer')
      await dispatch('getProducts')

      
      if (state.order && state.market !== oldMarket) {
        const backupLineItems = state.order?.line_items?.length ? state.order.line_items.filter(item => item.sku_code || item.bundle_code) : []
        const hasLineItemsToSync = backupLineItems.length
        if (hasLineItemsToSync) {
          let order = await dispatch('getOrder')
          if (order) {
            const oldLineItems = order.line_items?.length ? order.line_items : []
            if (oldLineItems && oldLineItems.length) {
              await Promise.all(oldLineItems.map(async item => {
                await dispatch('removeLineItem', item.id)
              }))
            }
          }
          for (const [index, item] of backupLineItems.entries()) {
            const isNewOrder = !order && index === 0
            await dispatch('addToCart', {...item, isSync: !isNewOrder})
          }
          order = await dispatch('getOrder')
        }
      } else {
        const order = await dispatch('getOrder')
        if (order) {
          const locale = this.app.i18n.locale
          const attributes = { 
            shipping_country_code_lock: state.country.code, 
            // country_code: state.country.code, 
            terms_url: await dispatch('pageUrl','terms'),
            privacy_url: await dispatch('pageUrl','privacy'),
            cart_url: await dispatch('pageUrl','cart'),
            language_code: locale
          }
          await dispatch('updateOrder', attributes)
        }
      }

    await commit('setLoading', false)

    return activeMarket
  },

  async setBusiness({
    commit, dispatch
  }, business) {
    await commit('setBusiness', business)
    await dispatch('setMarket', true)
    return business
  },
  async setCountry({
    commit, dispatch
  }, country) {
    await commit('setCountry', country)
    await dispatch('setMarket', true)
    return country
  },
  async setCustomerEmail({
    commit
  }, email) {
    await commit('setCustomerEmail', email)
    return email
  },
  async getLocation({ dispatch }) {
    let country
    let storageCountry = false
    if (process.browser) {
      storageCountry = localStorage.getItem(`vitesyCountry`)
    }
    if (storageCountry && storageCountry !== 'false') {
      country = JSON.parse(storageCountry)
    } else {
      country = await new Promise((resolve, reject) => {
        if (process.browser) {
          if (typeof geoip2 === 'undefined') {
            // reject(new Error('geoip2 undefined'))
            resolve(false)
          } else {
            geoip2.country(
              (response) => {
                // const country = { code: response.country.iso_code, is_eu: response.country.is_in_european_union, name: { en: response.country.names.en, it: response.country.names.it }}
                const country = countries.find(c => c.code === response.country.iso_code)
                resolve(country)
              }, (err) => {
                reject(err)
              })
          }
        } else {
          return resolve(false)
        }
      })
    }
    await dispatch('setCountry', country)
  },
  // Order
  async getOrder({
    commit,
    dispatch,
    state
  }, id) {
    // await dispatch('initCommerceLayer')
    let orderId = id
    let order
    if (!order) {
      let storageOrderId = false
      if (process.browser) {
        storageOrderId = localStorage.getItem(`vitesyOrderId_${state.market}`)
      }
      orderId = id || storageOrderId
      if (orderId) {
        try {
          order = await CommerceLayer({
            organization,
            accessToken: state.accessToken
          }).orders.retrieve(orderId, {
            include: ['line_items']
          })
        } catch (err) {
          console.error(err)
          // if (process.browser) {
          //   localStorage.removeItem(`vitesyOrderId_${state.market}`)
          // }
          order = false
        }
      } else {
        order = false
        // try {
        //   const locale = this.app.i18n.locale
        //   order = await CommerceLayer({
        //     organization,
        //     accessToken: state.accessToken
        //   }).orders.create({
        //     shipping_country_code_lock: state.country.code,
        //   })
        // } catch (err) {
        //   console.error(err)
        //   order = false
        // }
      }
    }
    commit('setOrder', order)
    return order
  },

  // Cart Management
  async addToCart({
    state,
    dispatch
  }, product) {
    if (!state.order) {
      await dispatch('createOrder')
    }
    if (state.order) {
      const attributes = {
        [product.isBundle ? 'bundle_code' : 'sku_code']: product.sku_code,
        quantity: product.quantity,
        name: product.name,
        image_url: product.image_url,
        _update_quantity: true,
        order: state.order
      }
      product.not_update_quantity ? attributes._update_quantity = false : ''

      const cartProduct = state.order.line_items?.find(item => item.sku_code === product.sku_code)
      if (!cartProduct || cartProduct.quantity < 10) {
        await CommerceLayer({
          organization,
          accessToken: state.accessToken
        }).line_items.create(attributes)
        !product.isSync ? await dispatch('getOrder', state.order.id) : ''
      }
    }
  },
  async updateLineItem({
    state,
    dispatch
  }, item) {
    if (item) {
      try {
        const lineItem = {
          id: item.id,
          quantity: item.quantity
        }
        await CommerceLayer({
          organization,
          accessToken: state.accessToken
        }).line_items.update(lineItem)
        await dispatch('getOrder', state.order.id)
      } catch (error){
        console.log(error)
      }
    }
  },
  async removeLineItem({
    state,
    dispatch
  }, itemId) {
    if (itemId) {
      try {
        await CommerceLayer({
          organization,
          accessToken: state.accessToken
        }).line_items.delete(itemId)
        // await dispatch('getOrder', state.order.id)
      } catch (error){
        console.log(error)
      }
    }
  },
  async applyCoupon({
    state,
    dispatch
  }, { couponCode, toastNotification }) {
    if (!state.order) {
      await dispatch('createOrder')
    }
    if (state.order) {
      try {
        const response = await CommerceLayer({
          organization,
          accessToken: state.accessToken
        }).orders.update({
          id: state.order.id,
          coupon_code: couponCode,
          customer_email: state.customerEmail || ''
        })
        await dispatch('getOrder', state.order.id)
        
        if (response && toastNotification) {
          dispatch('showToast', {
            message: toastNotification.message,
            duration: toastNotification.duration || 4000
          })
        }

        return response
      } catch (error) {
        return error.errors
      }
    }
  },
  async createOrder({
    state,
    commit,
    dispatch
  }) {
    let order = false
    try {
      const locale = this.app.i18n.locale
      const attributes = { 
        shipping_country_code_lock: state.country.code, 
        terms_url: await dispatch('pageUrl','terms'),
        privacy_url: await dispatch('pageUrl','privacy'),
        cart_url: await dispatch('pageUrl','cart'),
        language_code: locale,
        customer_email: state.customerEmail || ''
      }
      order = await CommerceLayer({
        organization,
        accessToken: state.accessToken
      }).orders.create(attributes)
    } catch (err) {
      console.error(err)
    }
    await commit('setOrder', order)
  },
  async updateOrder({
    state,
    dispatch
  }, attributes) {
    if (state.order) {
      try {
        const response = await CommerceLayer({
          organization,
          accessToken: state.accessToken
        }).orders.update({
          id: state.order.id,
          customer_email: state.customerEmail || '',
          ...attributes
        })
        await dispatch('getOrder', state.order.id)
        return response
      } catch (error) {
        return error.errors
      }
    }
  },
  // Products
  async getProducts({
    commit,
    dispatch,
    state
  }) {
    // const mappedProducts = state.products.length ? state.products : []
    const mappedProducts = []
    const products = await CommerceLayer({
      organization,
      accessToken: state.accessToken
    }).skus.list({
      pageSize: 20,
      include: ['prices','prices.price_tiers', 'stock_items', 'sku_options']
    })

    // const bundles = await CommerceLayer({
    //   organization,
    //   accessToken: state.accessToken
    // }).bundles.list({
    //   include: ['skus', 'sku_list']
    // })
    // const allProducts = [...products, ...bundles]
    // await Promise.all(allProducts.map(async product => {
    await Promise.all(products.map(async product => {

      // //Price Tiers
      // let price_tiers
      // if(product.code === 'SH00AA00') {
      //   const response = await CommerceLayer({
      //     organization,
      //     accessToken: state.accessToken
      //   }).prices.retrieve(product.prices[0].id, { include: ['price_tiers'] })
      //   product.price_tiers = response?.price_tiers
      // }
        
      //Bundle
      // const isBundle = product.type === 'bundles'
      // const bundle = isBundle ? await CommerceLayer({
      //   organization,
      //   accessToken: state.accessToken
      // }).sku_lists.retrieve(product.sku_list.id, {
      //   include: ['sku_list_items']
      // }) : []
      // mappedProducts.push({
      //   sku_code: product.code,
      //   name: product.name,
      //   description: product.description,
      //   quantity: 1,
      //   price: isBundle ? product.price_amount_float : product.prices[0].amount_float,
      //   price_tiers: product.prices[0].price_tiers,
      //   compare_at_price: isBundle ? product.compare_at_amount_float : product.prices[0].compare_at_amount_float,
      //   currency_code: isBundle ? product.currency_code : product.prices[0].currency_code,
      //   stock: isBundle ? 1000 : product.stock_items[0].quantity,
      //   available: isBundle ? true : product.stock_items[0].quantity > 0,
      //   image_url: product.image_url,
      //   isBundle,
      //   sku_options: product.sku_options,
      //   bundle_items: bundle.sku_list_items,
      //   category: product.metadata.category || 'Other',
      //   metadata: product.metadata
      // })
      const stock =  await dispatch('getStockByCountry', product)

      mappedProducts.push({
        sku_code: product.code,
        name: product.name,
        description: product.description,
        quantity: 1,
        price: product.prices[0].amount_float,
        price_tiers: product.prices[0].price_tiers,
        compare_at_price: product.prices[0].compare_at_amount_float,
        currency_code: product.prices[0].currency_code,
        stock: stock,
        available: stock > 0,
        image_url: product.image_url,
        sku_options: product.sku_options,
        // bundle_items: bundle.sku_list_items,
        category: product.metadata.category || 'Other',
        metadata: product.metadata
      })
    }))
    await commit('setProducts', mappedProducts)
    await commit('setCurrency', mappedProducts[0].currency_code)
    return mappedProducts
  },
  async getStockByCountry({ state }, product) {
    const PLUG_C = ["AD", "AF", "AL", "AO", "AR", "AT", "AZ", "BA", "BE", "BF", "BG", "BI", "BR", "BY", "CD", "CG", "CH", "CI", "CL", "CM", "CV", "CZ", "DE", "DK", "DZ", "EE", "EG", "ER", "ES", "FI", "FR", "GL", "GR", "HR", "HU", "IC", "ID", "IL", "IN", "IR", "IS", "IT", "KP", "KR", "KZ", "LU", "LV", "MA", "MC", "MD", "ME", "MG", "MK", "MN", "MZ", "NL", "NO", "NP", "PK", "PL", "PT", "PY", "RO", "RS", "RU", "SD", "SE", "SI", "SK", "SN", "SO", "SS", "SY", "TG", "TN", "TR", "UA", "UY", "VN", "XK", "ZM"]
    const PLUG_G = ["GB","UK","AE","AM","BD","BN","BT","CY","GH","GM","HK","IE","IQ","JO","KE","KW","MM","MO","MT","MU","MV","MY","NG","QA","SC","SG","SL","TZ","YE","ZM","ZW"]
    const PLUG_I = ["AU","CN","NZ"]
    const PLUG_A = ["BB","BM","BO","BS","CO","CR","CU","CW","DO","EC","GT","HN","HT","JP","KH","KY","MX","NI","PA","PE","PF","PH","PR","TH","TW","US","CA","VE"]
    const NATEDE_BASIC_COLORED = ['NT02AA03','NT02AA04','NT02AA06']
    const PRODUCTS_WITH_PLUG =  ["NT01","NT02", "NT03", "ET00AA", "ET00AB"]
    
    const country = state.country?.code
    const sku = product.code
    let plug = null
    let stock = product.stock_items[0].quantity
  
    const hasPlug = PRODUCTS_WITH_PLUG.some(skuPart => sku.includes(skuPart))
    
    if (product.metadata.inventory) {
      if (sku.startsWith('SH00AA0')) {
        // Shelfy product handling
        let shelfySku;
        switch (country.toUpperCase()) {
          case 'IT':
            // shelfySku = 'SH00AA00';
            shelfySku = 'SH00AA02';
            break;
          case 'DE':
            shelfySku = 'SH00AA03';
            break;
          case 'FR':
            shelfySku = 'SH00AA04';
            break;
          case 'ES':
            shelfySku = 'SH00AA05';
            break;
          case 'US':
            shelfySku = 'SH00AA06';
            break;
          case 'CA':
            shelfySku = 'SH00AA06';
            break;
          default:
            // shelfySku = 'SH00AA00';
            shelfySku = 'SH00AA01'; // Default to English (EN) SKU
        }
        stock = product.metadata.inventory[shelfySku] || stock;
      } else if (hasPlug) {
        if (PLUG_C.includes(country)) {
          plug = "C"
        } else if (PLUG_G.includes(country)) {
          plug = "B"
          sku.includes("ET00A") ? plug = "G" : ""
        } else if (PLUG_I.includes(country)) {
          plug = "B"
          sku.includes("ET00A") ? plug = "I" : ""
        } else if (PLUG_A.includes(country)) {
          plug = "A"
          NATEDE_BASIC_COLORED.includes(sku) ? plug = "B" : ""
        } else {
          plug = "C"
        }
        
        if (product.code === 'NT02AA00' && country === 'IT') {
          stock = product.metadata.inventory[`NT02AB00_${plug}`]
        } else {
          stock = product.metadata.inventory[`${product.code}_${plug}`]
        }
      } else {
        stock = product.metadata.inventory[product.code] || stock
      }
    }
  
    return stock
  },
  showToast({ commit, dispatch }, { message, duration = 5000 }) {
    commit('showToast', message);
    setTimeout(() => {
      dispatch('hideToast');
    }, duration);
  },
  hideToast({ commit }) {
    commit('hideToast');
  }
}

export const getters = {
  activePage: state => {
    return state.activePage
  },
  accessToken: state => {
    return state.accessToken
  },
  country: state => {
    return state.country ? { ...state.country } : false
  },
  market: state => {
    return state.market
  },
  currency: state => {
    return state.currency
  },
  business: state => {
    return state.business
  },
  customerEmail: state => {
    return state.customerEmail
  },
  orderId: state => {
    return state.order.id
  },
  orderNumber: state => {
    return state.order.number
  },
  order: state => {
    return { ...state.order }
  },
  products: state => {
    return [ ...state.products ]
  },
  product: (state) => (sku) => {
    return { ...state.products.find(p => p.sku_code === sku) }
  },
  productCategory: () => (sku) => {
    if (sku) {
      if (sku.includes('SH'))
        return 'Shelfy'
      if (sku.includes('ET'))
        return 'Eteria'
      if (sku.includes('NT'))
        return 'Natede'
      if (sku.includes('MK'))
        return 'T-Shirt'
      else 
        return 'Other'
    } else {
      return 'Other'
    }
  },
  isLoading: state => {
    return state.isLoading
  }
}
