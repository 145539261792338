
export default {
  name: 'FaqSearch',
  data() {
    return {
      keyword: '',
      search_results: [],
    }
  },
  computed: {
    isApp() { return this.$route.query.app }
  },
  methods: {
    async searchFAQ() {
      this.search_results = []
      if (this.keyword.length > 2) {
        const lang = this.$i18n.locales.filter((i) =>
          i.iso.includes(this.$i18n.locale)
        )[0].iso
        try {
          const faq = await this.$prismic.api.query(
            [
              this.$prismic.predicates.at('document.type', 'faq'),
              this.$prismic.predicates.fulltext('document', this.keyword),
            ],
            { lang }
          )
          this.search_results = faq.results
        } catch (e) {
          this.search_results = []
        }
      }
    },
  },
}
