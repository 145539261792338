import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=7a7de5a2&scoped=true&lang=pug"
import script from "./SiteFooter.vue?vue&type=script&lang=js"
export * from "./SiteFooter.vue?vue&type=script&lang=js"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=7a7de5a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a7de5a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterSection: require('/opt/build/repo/components/NewsletterSection.vue').default,FooterNavigation: require('/opt/build/repo/components/FooterNavigation.vue').default,Chatbot: require('/opt/build/repo/components/Chatbot.vue').default})
