import { render, staticRenderFns } from "./faq.vue?vue&type=template&id=4da77d0a"
import script from "./faq.vue?vue&type=script&lang=js"
export * from "./faq.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Topbar: require('/opt/build/repo/components/Topbar.vue').default,SiteHeader: require('/opt/build/repo/components/SiteHeader.vue').default,FaqSidebar: require('/opt/build/repo/components/faq/FaqSidebar.vue').default,NuxtLinkCustom: require('/opt/build/repo/components/NuxtLinkCustom.vue').default,SiteFooter: require('/opt/build/repo/components/SiteFooter.vue').default})
