
export default {
  name: 'Chatbot',
  props: ['fullSize','bottom'],
  data(){
    return { 
      show: false,
      bottomPosition: 110
     }
  },
  computed: {
    country(){
      return this.$store.getters.country?.code
    },
    userId(){
      const orderNumber = this.$store.getters.orderNumber;
      const customerEmail = this.$store.getters.customerEmail;
      
      if (!orderNumber && !customerEmail) {
        return null;
      }
      
      if (orderNumber && customerEmail) {
        return `${orderNumber} - ${customerEmail}`;
      }
      
      return orderNumber || customerEmail;
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 1000)
    this.bottomPosition = this.bottom || window.innerWidth < 768 ? 120 : null
    
    // document.addEventListener("DOMContentLoaded", function () {
    //   function openKepleroPopover() {
    //     const popoverTriggerEvent = new CustomEvent(
    //       "keplero-popover-trigger",
    //       {
    //         detail: {
    //           chatbot: "16662b44-a7b3-42d7-92bd-bca632c45b83",
    //         },
    //       }
    //     );
    //     window.dispatchEvent(popoverTriggerEvent);
    //   }

    //   document
    //     .querySelectorAll("#chatbot-trigger")
    //     .addEventListener("click", (e) => {
    //       e.stopPropagation();
    //       openKepleroPopover();
    //     });
    // });
}
}
